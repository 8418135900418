import React from 'react'

import BaseLayout from '../layouts/base.js'

export default class Terms extends React.Component {
  render() {
    return (   
      <BaseLayout title="Carrick Issues"
      description="Track GitHub Issues directly from Help Scout. Carrick Issues integrates your Help Scout mailbox directly with GitHub so your team can coordinate faster and serve customers better."
      shortDescription="Track GitHub Issues directly from Help Scout">
        <div class="error-body">
          <h1>
            <img src="/img/logo.svg" alt="Carrick Integrations" />
            Carrick
          </h1>
          <h2>Oof - that wasn't supposed to happen!</h2>
          <p>Sorry for the error there - we've logged it and will take a look ASAP. In the mean time, you can head to our <a href="https://carrick.joewegner.com">Homepage</a> or jump back to <a href="https://carrick-processor.herokuapp.com/config/issues">your app</a>. If you keep getting that error, please <a href="mailto:carrick@joewegner.com">reach out to Support.</a></p>
        </div>  
      </BaseLayout>
    )
  }
}